import React, { useEffect, useState } from "react";
import "./App.css";
import {
  Button,
  Col,
  Container,
  Fade,
  Form,
  Modal,
  ModalBody,
  Row,
} from "react-bootstrap";
import X3dScene1 from "./components/X3dscene1/X3dScene1";
import "./components/components.css";
import XControls from "./components/XControls/XControls";
import { IXApartmentProps } from "./components/xbuilding/XBuilding";

function App() {
  const [stIsFormOpen, setStIsFormOpen] = useState(false);
  const [stFormCurrentApartmentId, setStFormCurrentApartmentId] =
    useState("-999");
  const [
    stFormCurrentApartmentDescription,
    setStFormCurrentApartmentDescription,
  ] = useState("???");
  const [stFormCurrentApartmentPiso, setStFormCurrentApartmentPiso] =
    useState("-999");
  const [stFormCurrentApartmentTipo, setStFormCurrentApartmentTipo] =
    useState("???");
  const [stFormCurrentApartmentCodigo, setStFormCurrentApartmentCodigo] =
    useState("???");
  const [stFormCurrentApartmentPrecio, setStFormCurrentApartmentPrecio] =
    useState("-999");

  const handleClose = () => setStIsFormOpen(false);
  const handleShow = () => setStIsFormOpen(true);

  // const scrollArea = useRef()
  // const onScroll = e => (state.top.current = e.target.scrollTop)
  // useEffect(() => void onScroll({ target: scrollArea.current }), [])

  const tcurrentApartment: IXApartmentProps = {
    id: "-999",
    disponible: "0",
    piso: "-999",
    tipo: "???",
    codigo: "???",
    precio: "-999",
  };
  const thandlerContactForm = (rapartment: IXApartmentProps) => {
    console.log("thandlerContactForm! " + rapartment.id.toString());
    setStFormCurrentApartmentId(rapartment.id.toString());
    setStFormCurrentApartmentCodigo("" + rapartment.codigo.toString());
    setStFormCurrentApartmentPrecio(rapartment.precio.toString());
    setStIsFormOpen(!stIsFormOpen);
  };

  useEffect(() => {
    // tcurrentApartment.
    console.log("useEffect! " + stFormCurrentApartmentId);
  }, [stFormCurrentApartmentId]);

  return (
    <div className="App">
      <X3dScene1 thandlerContactForm={thandlerContactForm} />
      <div>
        <Button
          onClick={() => setStIsFormOpen(!stIsFormOpen)}
          aria-controls="example-collapse-text"
          aria-expanded={stIsFormOpen}
        >
          FORMULARIO
        </Button>
      </div>

      {/* <Fade in={stIsFormOpen}>
        <div>FORM</div>
      </Fade> */}

      <Modal show={stIsFormOpen} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Apartamento {stFormCurrentApartmentCodigo}{" "}
            {stFormCurrentApartmentTipo}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {/* [stFormCurrentApartmentId:{stFormCurrentApartmentId}]<br /> */}
            {/* [stFormCurrentApartmentTipo:{stFormCurrentApartmentTipo}]<br /> */}
            {/* [stFormCurrentApartmentCodigo:{stFormCurrentApartmentCodigo}]<br /> */}
          </div>

          <Container>
            <Row>
              <Col md={6}>[img][data1]</Col>
              <Col md={6}>[data2]</Col>
            </Row>
          </Container>

          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>

            <Form.Group
              // as={Row}
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="2">
                ID
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  plaintext
                  readOnly
                  defaultValue={stFormCurrentApartmentId}
                />
              </Col>
            </Form.Group>

            <Form.Group
              // as={Row}
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="2">
                Precio
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  plaintext
                  readOnly
                  defaultValue={stFormCurrentApartmentPrecio}
                />
              </Col>
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" />
            </Form.Group>
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check type="checkbox" label="Check me out" />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default App;
