import React from "react";
import XBuildingApartments from "../XBuildingApartments/XBuildingApartments";
import * as THREE from "three";
import ArgumentumRegular from "../../assets/fonts/ArgumentumRegular.json";
// import * as taptsjson from "../../assets/allapts.json";


export interface IXApartmentProps {
  id: string;
  disponible: string;
  piso: string;
  tipo: string;
  codigo: string;
  precio: string;
}

export interface IXBuildingProps {
  posx: number;
  posy: number;
  posz: number;
  thandlerContactForm: (rapartment: IXApartmentProps) => void;
}

export const XBuilding = (props: IXBuildingProps) => {
  // ☞

  // const aptsjson = taptsjson;
  const aptsjson = require('../../assets/allapts.json');
  console.log("aptsjson!!",aptsjson);
  
  // const aptsjson = {
  //   apartamentos: [
  //     {
  //       id: 1,
  //       disponible: 1,
  //       piso: 1,
  //       tipo: "A",
  //       codigo: "101",
  //       precio: 500001,
  //       pos: [1, 1, 1],
  //     },
  //     {
  //       id: 2,
  //       disponible: 0,
  //       piso: 1,
  //       tipo: "A",
  //       codigo: "102",
  //       precio: 500002,
  //       pos: [1, 1, 1],
  //     },
  //     {
  //       id: 3,
  //       disponible: 0,
  //       piso: 1,
  //       tipo: "A",
  //       codigo: "103",
  //       precio: 500003,
  //       pos: [1, 1, 1],
  //     },
  //     {
  //       id: 4,
  //       disponible: 1,
  //       piso: 2,
  //       tipo: "A",
  //       codigo: "201",
  //       precio: 500001,
  //       pos: [1, 1, 1],
  //     },
  //     {
  //       id: 5,
  //       disponible: 1,
  //       piso: 2,
  //       tipo: "A",
  //       codigo: "202",
  //       precio: 500002,
  //       pos: [1, 1, 1],
  //     },
  //     {
  //       id: 6,
  //       disponible: 1,
  //       piso: 2,
  //       tipo: "A",
  //       codigo: "203",
  //       precio: 500003,
  //       pos: [1, 1, 1],
  //     },
  //   ],
  // };


  // fetch("http://tera12.localhost/wp-json/cotizatower/v1/allapartments")
  // .then(response => response.json())
  // .then((jsonData) => {
  //   // jsonData is parsed json object received from url
  //   console.log(jsonData)
  // })
  // .catch((error) => {
  //   // handle your errors here
  //   console.error(error)
  // })




  const font = new THREE.FontLoader().parse(ArgumentumRegular);
  const textOptions = {
    font,
    size: 1.1,
    height: 0.1,
    bevelEnabled: true,
    bevelThickness: 0.1,
		bevelSize: 0.0,
		bevelOffset: 0,
		bevelSegments: 2
  };
  // const three_texture = new THREE.TextureLoader().load(texture)
  // three_texture.wrapS = THREE.RepeatWrapping
  // three_texture.wrapT = THREE.RepeatWrapping
  // three_texture.repeat.set(0.1, 0.1);

  let tx = -10;
  let ty = 0;
  let tz = 0;

  let tperfloor = 6;
  let tcount = 0;

  // for (let ii = 0; ii < 10; ii++) {}


  // if (false) {
  const allapts = aptsjson.apartamentos.map((vv, kk) => {
    tx += 7.5;
    tcount++;
    if (tcount == tperfloor) {
      tcount = 1;
      tx = 0;
      ty += 3;
    }

    const ttapt: IXApartmentProps = {
      id: vv.id.toString(),
      disponible: vv.disponible.toString(),
      piso: vv.piso.toString(),
      tipo: vv.tipo.toString(),
      codigo: vv.codigo.toString(),
      precio: vv.precio_fase_1.toString(),
    };

    const tmaterialApartment: THREE.MeshBasicMaterial =
      new THREE.MeshBasicMaterial({ color: 0xffcc00 }); //{ color: 0x00ff00, wireframe: true })
    const tmaterialApartmentText: THREE.MeshBasicMaterial =
      new THREE.MeshBasicMaterial({ color: 0xff000 }); //{ color: 0x00ff00, wireframe: true })
    const tmaterialFloor: THREE.MeshBasicMaterial = new THREE.MeshBasicMaterial(
      { color: 0x111111 }
    ); //{ color: 0x00ff00, wireframe: true })

      const tcolorDisponible0:number = 0xb55757;
      const tcolorDisponible1:number = 0x58b4b1;

    return (
      <group key={"taptGroup_"+vv.id}>
        <mesh
          key={"taptMesh_" + vv.id}
          position={[tx, ty, tz]}
          onClick={(rev) => {
            props.thandlerContactForm(ttapt);
            // console.log("click!"+vv.id,rev);
          }}
        >
          {/* <boxBufferGeometry attach="geometry" args={[3, 3, 3]} /> */}
          {/* <meshNormalMaterial attach="material" /> */}

          <meshBasicMaterial attach="material" color={vv.disponible == "1" ? tcolorDisponible1 : tcolorDisponible0} />
          <boxGeometry args={[7, 1, 0.7]} />
        </mesh>
        <mesh key={"taptText_" + vv.id} position={[tx - 1.5, ty - 0, tz + 0.3]}>
          <textGeometry
            attach="geometry"
            args={[vv.codigo + "" + vv.tipo, textOptions]}
          />
          <meshBasicMaterial
            attach="material"
            // color={0x6599cb}
            color={0x333}
            // args={{
            // map: three_texture
            //  }}
          />
        </mesh>
      </group>
    );
  });
  // }

  return (
    <>
      <mesh key="tfloor" position={[props.posx, props.posy - 2, props.posz]}>
        <boxBufferGeometry attach="geometry" args={[20, 0.3, 7]} />
        <meshNormalMaterial attach="material" />
      </mesh>
      {allapts}
    </>
  );
};
