import { Canvas } from "@react-three/fiber";
import * as React from "react";
import { Button } from "react-bootstrap";
import { IXApartmentProps, XBuilding } from "../xbuilding/XBuilding";
import XControls from "../XControls/XControls";

export interface IX3dScene1Props {
  thandlerContactForm: (rapartment:IXApartmentProps) => void;
}

export default function X3dScene1(props: IX3dScene1Props) {
  const reftcamera1 = React.useRef("tcamera1");
  const tmpT1 = () => {
    // reftcamera1.current
  };
  const tmpT2 = () => {};

  function updateCamera(ev) {
    // let div1 = document.getElementById("div1");
    // camera.position.x = 10 - window.scrollY / 500.0;
    // camera.position.z = 10 - window.scrollY / 500.0;
    console.log("updatecamera", ev);
  }

  // window.addEventListener("scroll", updateCamera);

  return (
    <div className="DivX3dScene1">
      <Button onClick={tmpT1}>T1</Button>
      <Button onClick={tmpT2}>T2</Button>
      <Canvas
      // camera={{ position: [0, 21, 17], rotation: [0, 0, 0] }}
      >
        <pointLight position={[10, 10, 10]} />
        <camera key={reftcamera1} position={[0, 75, 37]} rotation={[0, 0, 0]} />

        {/* <ambientLight intensity={0.5} /> */}
        {/* <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} /> */}
        {/* <pointLight position={[-10, -10, -10]} /> */}

        <XBuilding
          posx={0}
          posy={0}
          posz={0}
          thandlerContactForm={props.thandlerContactForm}
        />
        <XControls />
      </Canvas>
    </div>
  );
}
